import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { subpageContent, columnCards, sliderCompanies } from "../utils/products/subpageInformation";
import { bannerCMS } from "../utils/generalInformation";
import ImageCarousel from "../components/image-carousel/image-carousel";
import Comparison from "../components/comparison/comparison";
import InfoImage from "../components/info-image/info-image";
import Banner from "../components/banner/banner";




// markup
const Motor = () => {


    return (
        <Layout
            SEOTitle={"Motor Legal Expenses Products - United Legal Assistance"}
            SEODescription={"Motor Legal Expenses Products - We make sure all the right steps are taken quickly to deal with the aftermath of the accident."}
            footerDisclaimer={""}>

            <InfoImage props={subpageContent} origin="products" />
            <InfoImage props={subpageContent} origin="policy" />
            <InfoImage props={subpageContent} origin="cover" />

            <Banner props={bannerCMS} />

            <Comparison props={columnCards} />

            <ImageCarousel props={sliderCompanies} />

        </Layout>
    )

}

export default Motor